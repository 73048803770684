<template>
  <section class="ma-5">
    <p class="text-h5 secondary--text my-4">Modalidades de compra</p>
    <br />
    <v-row>
      <v-col cols="4">
        <v-btn @click="addModalidadDialog = true" dark block color="secondary">
          Agregar modalidad
        </v-btn>
      </v-col>
    </v-row>
    <p class="mt-6">Listado de modalidades</p>
    <v-data-table :headers="encabezado" :items="modalidades">
      <template #[`item.acciones`]="{ item }">
        <v-tooltip right left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              small
              :to="{
                name: 'detalle-configuracion-proceso-compra',
                params: { id_modalidad: item.id },
              }"
            >
              <v-icon color="secondary">mdi-cog</v-icon>
            </v-btn>
          </template>
          <span> Configurar modalidad</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog
      persistent
      max-width="600"
      class="modal"
      v-model="addModalidadDialog"
    >
      <v-card max-width="600">
        <v-btn
          @click="(addModalidadDialog = false), clearInfo()"
          icon
          color="secondary"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
        <v-card-text>
          <section>
            <v-row>
              <p class="text-h5 secondary--text mx-4">Agregar modalidad</p>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Nombre *"
                  outlined
                  v-model="modalidad_form.nombre"
                  :error-messages="nombreError"
                  @input="$v.modalidad_form.nombre.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Código *"
                  outlined
                  v-model="modalidad_form.codigo"
                  :error-messages="codigoError"
                  @input="$v.modalidad_form.codigo.$touch()"
                />
              </v-col>
            </v-row>
            <v-row align="center">
              <v-btn
                @click="(addModalidadDialog = false), clearInfo()"
                outlined
                color="secondary"
                >Cancelar</v-btn
              >
              <v-btn
                @click="addModalidad"
                color="secondary white--text"
                class="mx-4"
                >Agregar</v-btn
              >
            </v-row>
          </section>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Reportes que se programan para ejecutarse enero 31 -->
    <v-row class="mt-8">
      <v-col cols="3" sm="1" v-if="moment().format('YYYY') === '2023'">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="programarReportePAC(true)"
              color="secondary"
              small
              dark
              block
              class="mx-2"
            >
              <v-icon>mdi-file-chart-outline</v-icon> 2023
            </v-btn>
          </template>
          <span>Programar ejecución de reporte PAC 2023</span>
        </v-tooltip>
      </v-col>
      <v-col cols="3" sm="1">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              @click="programarReportePAC()"
              color="secondary"
              small
              dark
              block
              class="mx-2"
            >
              <v-icon>mdi-file-chart-outline</v-icon> {{ Number(moment().format('M')) > 1 ? Number(moment().format('YYYY')) + 1 : Number(moment().format('YYYY')) }}
            </v-btn>
          </template>
          <span>Programar ejecución de reporte PAC {{ Number(moment().format('M')) > 1 ? Number(moment().format('YYYY')) + 1 : Number(moment().format('YYYY')) }}</span>
        </v-tooltip>
      </v-col>
      <v-col cols="6" sm="6" md="4" v-if="envioMasivo === 'true'">
        <v-text-field label="Correo de prueba" v-model="correos" outlined hide-details />
        <v-btn
          @click="pruebaCorreoMasivo()"
          color="secondary"
          small
          dark
        >Prueba de correos masivos</v-btn>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { helpers, required } from "vuelidate/lib/validators";

const letter = helpers.regex("letter", /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9 -]*$/);

export default {
  name: "listView",
  components: {},
  data: () => ({
    searchNombre: "",
    encabezado: [
      {
        text: "Modalidad",
        value: "nombre",
        align: "start",
      },
      {
        text: "Código",
        value: "codigo",
        align: "start",
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
      },
    ],
    modalidades: [],
    addModalidadDialog: false,
    modalidad_form: {
      nombre: null,
      codigo: null,
    },
    correos: '',
    envioMasivo: process.env.VUE_APP_SHOW_INPUT_MASIVO,
  }),
  validations: {
    modalidad_form: {
      nombre: { required, letter },
      codigo: { required, letter },
    },
  },
  computed: {
    nombreError() {
      const errors = [];
      if (!this.$v.modalidad_form.nombre.$dirty) return errors;
      !this.$v.modalidad_form.nombre.required &&
        errors.push("El nombre es requerido");
      !this.$v.modalidad_form.nombre.letter &&
        errors.push("El nombre no puede contener caracteres especiales");
      return errors;
    },
    codigoError() {
      const errors = [];
      if (!this.$v.modalidad_form.codigo.$dirty) return errors;
      !this.$v.modalidad_form.codigo.required &&
        errors.push("El código es requerido");
      !this.$v.modalidad_form.codigo.letter &&
        errors.push("El código no puede contener caracteres especiales");
      return errors;
    },
  },
  methods: {
    async getModalidades() {
      const { status, data, headers } =
        await this.services.PacProcesos.getProcesoModalidad();

      if (status == 200) {
        this.modalidades = data;
        this.totalRows = Number(headers.total_rows);
      }
    },
    async addModalidad() {

      this.$v.$touch();
      if(this.$v.$invalid) {
        this.temporalAlert({
          show:true,
          type:"error",
          message:"Ingresar los datos requeridos"
        })
      }
      if (!this.$v.$invalid) {
        const { status } = await this.services.PacProcesos.postNuevaModalidad(
          this.modalidad_form
        );

        if (status == 201) {
          this.temporalAlert({
            show: true,
            message: `Modalidad agregada con éxito`,
            type: "success",
          });
          this.clearInfo();
          this.getModalidades();
          this.addModalidadDialog = false;
        }
      }
    },
    clearInfo() {
      this.modalidad_form.nombre = null;
      this.modalidad_form.codigo = null;
      this.$v.$reset();
    },
    async programarReportePAC(create_now = null) {

      const { status } = await this.services.PacProcesos.generarReportePAC(create_now ? '1' : '');
      if (status == 200) {
        this.temporalAlert({
          show: true,
          message: create_now 
            ? 'Se ha programado el reporte para ejecutarse en 1 minuto'
            : 'Reporte programado con éxito',
          type: "success",
        });
      }

    },
    async pruebaCorreoMasivo() {

      const correos = this.correos.split(',');
      console.log(correos);
      const { status } = await this.services.PacProcesos.pruebaCorreoMasivo({
        correos,
      });
      if (status == 200) {
        this.temporalAlert({
          show: true,
          message: 'Se han enviado los correos de prueba',
          type: "success",
        });
      }

    },
  },
  created() {
    this.getModalidades();
  },
};
</script>
